.NavBar {
  position: fixed;
  margin-top: 0;
  height: 90px;
  width: 100vw;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px); 
  box-shadow: 0 5px 20px -15px black;  
}

.NavBar img {
  display: flex;
  justify-content: flex-start;
  height: 136px;
}

.NavBar .navLogo a {
  transition: transform .60s ease-in-out;
}

.NavBar .navLogo a:hover {
  transform: rotate(370deg);
}
.NavBar .navLogo a:active {
  transform: rotate(370deg);
}

.NavBar a {
  margin-right: 2em;
  transition: .1s;
  text-align: center;
  text-decoration: none;
  padding-top: 15px;
  padding-left: 4px;
  padding-bottom: 15px;
  cursor: pointer;
  width: 50px;
}

.NavBar .navLogo a {
  padding: 0;
  margin: 0;
  position: relative;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResumeNav {
  color: #24a049;
  border: 2px solid #F0EAD6;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  height: 19px;
  min-width: 10px;
}

.ResumeNav:hover {
  top: 0 !important;
  color: #ffffff;
  background-color: rgb(146 146 146 / 15%);
  position: relative;
  width: 200px;
}

.NavBarText {
  text-align: center;
}

.NavBarText a:hover {
  color: #afd377;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.navlink {
  color: #F0EAD6;
}

@media (max-width: 780px) {    
  .svg-inline--fa {
    height: 3em;
  }
}

@media (max-width: 780px) {
  .NavBar {
    display: none;
  }
}

@media (max-width: 780px) {
  .NavBarText {
    display: none;
  }
}

@media (max-width: 780px) {
  .navlink {
    display: none;
  }
}

@media (max-width: 780px) {
  .ResumeNav {
    display: none;
  }
}
        /* MOBILE  NAV BAR **********************************************/
        .NavBarMobile {
          visibility: hidden;
        }
        
        .NavBarTextMobile {
          display: none;
        }
        
        .navlinkMobile {
          display: none;
          cursor: pointer;
        }
        
        @media (max-width: 780px) {
          .NavBarMobile {
            transition: visible 1s;
            visibility: visible;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: fixed;
            margin-top: 0;
            height: 100vh;
            width: 100vw;
            z-index: 4;
            justify-content: space-between;
            align-items: center;
            backdrop-filter: blur(10px);
            box-shadow: 0 5px 20px -15px black;
            right: 0;
          }
        }
        
        @media (max-width: 780px) {
          .NavBarTextMobile {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        
        @media (max-width: 780px) {
          .navlinkMobile {
            display: flex;
            text-align: right;
            font-size: large;
            margin: 2px;
            justify-content: flex-end space-around;
            align-items: flex-end;
            color: #F0EAD6;
            padding: 20px;
            text-decoration: none;
          }
        }
        
        @media (max-width: 780px) {
          .mobileButton {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 15px 0px;
            position: fixed;
            border-radius: 0px 0px 0px 10px;
            background-color: rgb(146 146 146 / 15%);
            right: 0;
            z-index: 5;
            cursor: pointer;
          }
        }
        
        @media (max-width: 780px) {
          .NavBarTextMobile img {
            /* height: 30px;
            width: 30px; */
          }
        }
        
        @media (max-width: 780px) {
          .Bars {
            visibility: visible;
            transition: 5s;
            color: #F0EAD6;
            padding: 10px;
          }
        }
        
        @media (max-width: 780px) {
          .resumeIcon {
            color: #F0EAD6;
            margin-top: 0.5em;
          }
        }
        @media (max-width: 400px) {
          a.ResumeNavMobile{
            display: none;
          }
        }
        .Resume {
          display: flex;
          flex-direction: row;
        }
        