    .About{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;

    z-index: 2; 
    font-size: large;  
    padding-top: 100px;
    }
    
    .AboutTitle{
      display: flex;
      justify-content: center;
      align-self: center;
      color:  #3E735C;
      font-size: x-large;
    }

    .AboutCard{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px;
        height: 90%;
        width: 70%;
        font-size: 16px;
        padding: 20px;
        background-color: rgb(146 146 146 / 15%);
        display: flex;
        justify-content: center;
        flex-direction: column; 
        text-align: center;
        color: #F0EAD6;
        border-radius: 0.2rem;
        transition: opacity 1s;
        transition: color  1s;
        transition: box-shadow 1s;
        opacity: 0.9;
    }
    
    .AboutsContainer{
    display: flex;
    flex-direction: row;

    }
    
    body{
        font-family: sans-serif;
        
      }
        .container{
          width:100px;
          height:100px;
          background:transparent;
          display:flex;
          align-items: center;
          justify-content: center;
        }
      
        .react-logo{
          display: inline-block;
          border-radius:50%;
          border:5px solid #80ed99;
      
          animation-name:rotate;
          animation-duration: 5s; 
          animation-iteration-count: infinite;
          animation-timing-function: linear;
      
        }
      
        .react-logo , .react-logo:before , .react-logo:after{
          position:absolute;
          display: inline-block;
          width:50px;
          height:17px;
          border-radius:50%;
          border:2.5px solid #80ed99;
        }
      
        .nucleo , .react-logo , .react-logo:before , .react-logo:after {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      
        .nucleo{
          width:10px;
          height:10px;
          border-radius:100%;
          background:#80ed99;
        }
      
        .react-logo:before , .react-logo:after{
          content:"";
        }
      
        .react-logo:after{
          transform:rotate(-57deg);
        }
      
        .react-logo:before{
          transform:rotate(57deg);
        }
        
      
        @keyframes rotate{
      
          100%{
            transform:rotate(360deg);
          }
        }
      