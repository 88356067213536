.LifeExperience{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;

    z-index: 2; 
    font-size: large;  
    padding-top: 200px;
    }

    .wwd1{
        width: 20vw; 
        border-radius: 10px;
        cursor: pointer;
        z-index: 3;  
        transition: box-shadow 1s;  
        box-shadow: 0 0 0 0 #3E735C;
        /* transform: scale(0);
        animation: pulse 2s infinite;     */
    }
    
    .wwd2{
        width: 16vw; 
        border-radius: 10px;
        cursor: pointer;
        margin: 10px;   
        z-index: 3;  
        transition: box-shadow 1s;  
        box-shadow: 0 0 0 0 #3E735C;
        /* transform: scale(0);
        animation: pulse 2s infinite;     */
    }
    img.wwd:hover{
        filter: none;
    }

    .LifeExperienceTitle{
    display: flex;
    justify-content: center;
    align-self: center;
    color:  #3E735C;
    font-size: x-large;
    }

    .cOTALink{
        text-decoration: none;
        cursor: pointer;
        color: #F0EAD6;
        text-decoration: underline;
    }

    .cOTALink :hover{
        color: #afd377; 
    }

    .cOTALink :hover{
        color: #afd377;
        cursor: pointer;
        position: relative; 
        top: -1px;
    }
    .modelingCard{
        height: fit-content;
        background-color: rgb(146 146 146 / 15%);;
        flex-direction: row; 
    }

    .LifeExperienceCardCOTA{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px;
        height: fit-content;
        font-size: 16px;
        padding: 20px;
        height: 90%;
        max-width: 1210px;
        width: 80%;
        background-color: rgb(146 146 146 / 15%);;
        display: flex;
        justify-content: center;
        flex-direction: column; 
        text-align: center;  
        color: #F0EAD6;
        border-radius: 0.2rem;
        transition: opacity 1s;
        transition: box-shadow .4s;
        margin-bottom: 100px;
        line-height: 1.6;
    }
    .LifeExperienceCardCOTA:hover{
        color: #F0EAD6;
        opacity: 1;       
    }

    
    .LifeExperienceCardBIF{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px;
        height: fit-content;
        padding: 2%;
        height: 90%;
        max-width: 1210px;
        width: 80%;
        background-color: rgb(146 146 146 / 15%);;
        display: flex;
        justify-content: center;
        color: #F0EAD6;
        flex-direction: row; 
        border-radius: 0.2rem;
        transition: opacity 1s;
        transition: box-shadow .4s;        
        margin-bottom: 100px;
        line-height: 1.6;
    }
    .LifeExperienceCardBIF img {
        flex: 0 0 auto;
        width: auto;
        height: auto;
        max-height: 300px;
        object-fit: cover;
        object-position: center;
        margin-right: 20px;
      }
    .LifeExperienceCardBIF:hover{
        color: #F0EAD6;
        opacity: 1;
    }
    .LifeExperiencesContainer{
    display: flex;
    flex-direction: row;
    }

    .wwd1{
        display: none;
    }

    .LifeExperienceCardBIF p {
        flex: 1 1;
        text-align: left;
        font-size: 16px;
        padding-left: 40px;
    }
    @media (max-width: 780px) {
        .react-reveal .wwd2{
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 100%; */
        }
        .LifeExperienceCardBIF {            
            flex-direction: column;
            padding: 20px;
        }
        img.wwd{
            filter: none;
        }
        .LifeExperienceCardBIF p {
            flex: 1 1;
            text-align: center;
            padding-left: 0px;
        }
      }
      @media (max-width: 500px) {
        .LifeExperienceCardBIF, .LifeExperienceCardCOTA{
            width: 70%;
        }
      }
   