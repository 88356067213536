.App {
  text-align: center;
  min-height: 300px;
  overflow-x: hidden; 
  background-color: #121619;
  letter-spacing: 2px;
  line-height: 1.6;
}
/* p{
  font-size: 16px;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #121619;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color:  #F0EAD6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.link{
  color:  #F0EAD6;
  text-decoration: none;
}
.Container{
  display: flex;
 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 ; 
  background-color: #121619;
  z-index: 2;
  padding-top: 200px;
  font-size: xx-large;
  margin: 0px;
  height: 100%;
  width: 100vw;


  /* Center and scale the image nicely */
  background-position: center;
  background-size: cover;
}

@media (max-width: 557px){
  .Container{
      padding-top: 50px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: #0d0f11;
}

::-webkit-scrollbar-thumb {
  background: #afd377;
  border-radius: 20px;
  height: 5.6em;
  margin: 2px;
}

