    .Contact{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;

    z-index: 2; 
    font-size: large;  

    padding-bottom: 200px;
    }
    
    .ContactTitle{
        color:  #3E735C;
        font-size: x-large;
    }

    .ContactsContainer{
    display: flex;
    flex-direction: row;
    
    }

 .logo{
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    color: #F0EAD6;
 }

 .contactText{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    color: #F0EAD6;
 }
 .email :hover{
   color:  #afd377;
   border-radius: 50px;
   position: relative; 
   top: -1px;   
}
 .logo svg{
    margin: 30px;
    font-size: 200%;
    color: #F0EAD6;
    background: 50px color transparent;
    border-radius: 10px;
    cursor: pointer;
 }

 .logo svg :hover{
     color:  #afd377;
     border-radius: 50px;
     position: relative; 
     top: -1px;   
 }


 .gitubLogo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
 }

 .gitubLogo :hover{
   color:  #afd377;
   border-radius: 50px;
   position: relative; 
   top: -1px;   
}

.ContactCard {
   max-width: 70%;
   font-size: 16px;
}