.Name {
  color: #f0ead6;
}
.Greeting,
.MainText,
.Name,
.Objective,
.waveDiv {
  margin: 0px 25px;
  text-align: left;
}

.Greeting {
  color: #24a049;
  display: flex;
  margin: 0px 0px 0px px 25px;
  justify-content: flex-start;
}

.MainText {
  color: #f0ead6;
  font-size: 47px;
  width: 84%;
}

.landingText {
  max-width: 500px;
}

.waveDiv {
  color: #24a049;
  margin: 0px;
}

.Objective {
  color: #878da6;
  font-size: medium;
  font-size: large;
  position: relative;
  opacity: 0;
  animation: move-text 0.75s forwards;
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.LandingPage {
  justify-content: center;
  align-items: center;
  max-width: 1124px;
  width: 100%;
  background-image: url("https://my.spline.design/3dconcreteshapecopy-70b555b14ea5beadce8585eefb7c3667/");
  background-size: cover;
  background-position: center;
}
.shadow:hover {
  box-shadow: 0 8px rgba(46,240,88,.4), 0 19px rgba(46,240,70,.3), 0 27px rgba(46,240,70,.2), 0 38px rgba(46,240,70,.1), 0 41px rgba(94,240,46,.05);
}

.shadow, .shadow:hover {
  transition: box-shadow 2s ease;
}
.shadow {
  box-shadow: 0 5px rgba(46,240,88,.4), 0 10px rgba(46,240,70,.3), 0 15px rgba(46,240,70,.2), 0 20px rgba(46,240,70,.1), 0 25px rgba(94,240,46,.05);
}
@media (max-width: 557px) {
  .LandingPage {
    padding-left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1285px;
    margin-top: 40px;
  }

  .Greeting {
    color: #24a049;
    display: flex;
    justify-content: flex-start;
    width: 180px;
  }

  .Objective {
    /* padding-bottom: 140px; */
    padding-top: 22px;
  }
}

.headShot {
  width: 260px;
  float: right;
  opacity: 0.89;
  border-color: #3e735c;
  border-radius: 160px;
  padding: 2px;
  position: relative;
  z-index: 2;
}
@media (max-width: 570px) {
  .headShot {
    display: block;
    display: inline-block;
    width: 40%;
  }

  .planetCont {
    /* display: none; */
  }
}
@media (max-width: 823px) {
  .waveDiv {
    margin: 0px 0px 0px 25px;
  }
}
.embed-widget-container div:last-child {
  /* CSS styles for the last child divs */
  display: none;
}

.embed-widget-container.has-track-list .pILb1Mxt1ADw55uBp9MT {
  height: 0px !important;
  display: none;
}

div > .embed-widget-container.has-track-list > div {
  display: none !important;
  height: 0px;
}

p.react-reveal.Objective {
  width: 60%;
}

.spotify_widget {
  margin: 45px 0px 25px 0px;
  max-width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.planetCont {
  width: 100%;
  height: 500px;
}

a.spline-watermark {
  visibility: hidden;
}

a.spline-watermark {
  visibility: hidden;
  width: 0px;
}

.react-reveal.Greeting_wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
