
.testimonial-container{
  min-height: 500px;
  padding-top: 150px;
}
.testimonial-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-card {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px;
  width: 70%;
  max-width: 555px;
  padding: 20px;

  background-color: rgb(146 146 146 / 15%);
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden; /* Add overflow to prevent content from overflowing */
}

.testimonial-card h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #F0EAD6;
}
.kindwords{
  font-size: 18px;
  margin-bottom: 20px;
  color: #F0EAD6;

}
.testimonial-card h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #F0EAD6;
} 

.testimonial-card p {
  font-size: 16px;
  margin-top: 0px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: #F0EAD6;
}

.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-controls button {
  margin: 0 5px;
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.slider-controls button:hover {
  background-color: #0056b3;
}
